<template>
  <div
    class="bg-gray-50 border border-gray-200 sm:items-center call_queue_table_heading sticky top-0 flex flex-wrap justify-between"
  >
    <div class="col-start-2 col-end-3">
      <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
        <SearchFilter inputPlaceHolder="Search" v-model:search="searchNumb" />
      </div>
    </div>
  </div>
  <div class="pt-5 pr-10 pl-10 w-2/3 max-w-auto">
    <TablePagination
      :tableData="this.filterCallQueueList()"
      :level="this.level"
    />
  </div>
</template>

<script>
import axios from "axios";

import TablePagination from "../TablePagination.vue";
import SearchFilter from "../SearchFilter.vue";

const extensionsList = [{ label: "Queue - 819", value: 819, type: "queue" }];

export default {
  name: "PBXCallQueue",
  data() {
    return {
      level: "callQueues",
      pbxCallQueues: [],
      pbxCallQueueData: [],
      searchNumb: "",
      extensionsList,
    };
  },
  components: { TablePagination, SearchFilter },
  methods: {
    async getData() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/pbxMap/test.3cx.fr`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.pbxCallQueueData = res.data.callQueues;
      for (const key in this.pbxCallQueueData) {
        if (Object.hasOwnProperty.call(this.pbxCallQueueData, key)) {
          this.pbxCallQueues.push(this.pbxCallQueueData[key]);
        }
      }
    },
    filterCallQueueList() {
      return this.extensionsList.filter((callQueue) => {
        return String(callQueue.label)
          .toLowerCase()
          .includes(String(this.searchNumb).toLowerCase());
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.call_queue_table_heading {
  padding: 10px 40px 10px 40px;
}
</style>
